import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        </header>

	<div className="Footer">
	   <img src={logo} className="TrademarkLogo" alt="logo" />
	      <p className="Trademark">
		OpenMind ©2022 | All rights reserved
	       </p>
        </div>
    </div>
  );
}

export default App;
